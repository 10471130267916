import { executeWhenIdle } from '../delay';
import { beingExecdFrom, getUrlVars } from './util';
import lj from '../lumberjack';
import { newPricingInstrumentation } from '../new-pricing-instrumentation';
import trackGrowthAssets from './growth-assets';
import { neoInstrumentation } from './neo-instrumentation';
const analyticsUrl = 'https://cdn.razorpay.com/static/analytics/bundle.js';
const analyticsScr = document.createElement('script');
analyticsScr.src = analyticsUrl;
analyticsScr.onload = initAnalytics;
analyticsScr.async = true;

if (document.scripts) {
  let found = false;
  const isPagePaymentButtons = $('#payment-botton').length ? true : false;

  Array.from(document.scripts).forEach(script => {
    if (script.getAttribute('src') === analyticsUrl) {
      found = true;
    }
  });
  if (!found || isPagePaymentButtons) {
    document.documentElement.appendChild(analyticsScr);
  }
}

const isLocalEnv = () => {
  let isLocal = false;
  if (window.location.hostname === 'localhost') {
    isLocal = true;
  }
  return isLocal;
};

if (isLocalEnv() && !window.location.search.includes('loadRazorpayAnalytics')) {
  window.location.href = window.location.href + '?loadRazorpayAnalytics';
}

const hotjarEnabledPathnames = [
  '/',
  '/freelancer-individual-business/',
  '/docs/',
  '/payment-buttons/',
  '/upi-autopay/',
  '/best-payment-gateway-india/',
  '/support/',
  '/partners/',
  '/partners-new/',
  '/partners/onboarding-apis/',
  '/subscriptions/',
  '/smart-collect/',
  '/route/',
  '/e-mandate/',
  '/bharatqr/',
  '/upi/',
  '/access/signin',
  '/epos/',
  '/invoices/',
  '/payment-pages/',
  '/payment-links/',
  '/covid19-relief-fund/',
  '/payment-gateway/',
  '/payment-buttons-wordpress-plugin/',
  '/pricing/'
];

let isHotjarRequired = () =>
  hotjarEnabledPathnames.some(path => {
    return path === window.location.pathname;
  });

const currentPageID = $(document.body).attr('id');
const validNeoCouponCodes = {
  newYearCouponCode: 'NEWYEAR21',
  neoPricingCouponCode: 'NEORZP'
};
const prodPagesPricingChangeIDs = [
  'payments',
  'paymentlinks',
  'paymentpages',
  'payment-botton'
];
const currentCouponCode = (getUrlVars().couponCode || '').toUpperCase();
if (
  (prodPagesPricingChangeIDs.includes(currentPageID) ||
    currentPageID === 'pricing') &&
  currentCouponCode === validNeoCouponCodes.newYearCouponCode
) {
  newYearCouponCodePricing();
}

function initAnalytics() {
  let highPriorityAnalytics = [
    'fb',
    'twitter',
    'twitterAgency',
    'linkedin',
    'quora',
    'criteo',
    'lj'
  ];
  let lowPriorityAnalytics = ['bing', 'reddit'];
  const setupAnalytics = () => {
    isHotjarRequired() && highPriorityAnalytics.push('hotjar');
    razorpayAnalytics &&
      razorpayAnalytics.init(
        highPriorityAnalytics,
        { lj: 'ezGnY9wN3IjNwgDM3MDMygzNwMTO04yy' },
        isLocalEnv()
      );
    lj.setupLJ();
    if (window.location.pathname.includes('/x/corporate-cards')) {
      lj.initTrack();
    }
  };

  setupAnalytics();

  // Has a delay of atleast 3000ms
  executeWhenIdle(() => {
    razorpayAnalytics.init(lowPriorityAnalytics, isLocalEnv());
    razorpayAnalytics.initHubspot();
    razorpayAnalytics.track('reddit', 'PageVisit');
  });

  razorpayAnalytics.track('fb', 'PageView');
  razorpayAnalytics.track('twitter');
  razorpayAnalytics.track('linkedin');
  razorpayAnalytics.track('quora', 'ViewContent');

  if (currentPageID === 'pricing') {
    newPricingInstrumentation();
  } else if (
    prodPagesPricingChangeIDs.includes(currentPageID) &&
    currentCouponCode === validNeoCouponCodes.neoPricingCouponCode
  ) {
    neoInstrumentation(currentPageID);
  }
  trackGrowthAssets();
}

const fetchFreddyChatHistory = () => {
  const CHAT_MESSAGES_LIMIT = 120;

  const qs = function(selector, parent = document) {
    return parent.querySelector(selector);
  };

  const qsa = function(selector, parent = document) {
    const els = parent.querySelectorAll(selector);
    const a = [];
    for (let i = 0; i < els.length; i++) {
      a.push(els[i]);
    }
    return a;
  };

  const capitalizeFirstLetter = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatChatHistory = function(list) {
    if (list.length) {
      let out = '';
      list.forEach(function(item) {
        out +=
          '<b>' + capitalizeFirstLetter(item[0]) + '</b>: ' + item[1] + '\r\n';
      });
      return out;
    }
    return '';
  };

  const fetchChat = function(formatted = true) {
    const chatHistory = qs('div.chat-history ul.joe-message-list');
    const messageGroups = qsa('.joe-message-group', chatHistory);

    let author = 'Bot';
    const chat = [];

    messageGroups.forEach(function(msg) {
      author = msg.getAttribute('author');
      if (author) {
        const li = qsa('.joe-clearfix', msg);
        if (li.length) {
          li.forEach(function(li) {
            const div = qsa('.joe-message > span', li);
            if (div.length) {
              div.forEach(function(span) {
                if (span) {
                  chat.push([author, span.innerText]);
                }
              });
            } else {
              chat.push([author, li.innerText]);
            }
          });
        }
      }
    });

    if (formatted) {
      return formatChatHistory(chat.slice(-CHAT_MESSAGES_LIMIT));
    }

    return chat;
  };

  try {
    return fetchChat();
  } catch (e) {}

  return '';
};

/**
 * Executes a function once when a condition is true. Calls `condition` every `interval` milliseconds until it returns true, for a maximum of `maxTries` tries.
 *
 * @param {Function} condition A function that returns a Truthy/Falsy value
 * @param {Function} fn Function to be invoked
 * @param {Number} maxTries Maximum no. of tries
 * @param {Number} interval Interval in ms
 */
const invokeWhenTrue = (condition, fn, maxTries = 3, interval = 1000) => {
  let tries = 0;
  let currentTimeout = 0;

  const exec = () => {
    tries++;

    if (tries >= maxTries) {
      clearTimeout(currentTimeout);
      return;
    }

    if (condition()) {
      clearTimeout(currentTimeout);
      fn();
    } else {
      currentTimeout = setTimeout(exec, interval);
    }
  };

  exec();
};

const setupChatBotGA = fakeChatIcon => {
  const chatBubble = () => document.querySelector('.mobile-chat-container');
  const closeChatButton = () =>
    document.querySelector(
      fakeChatIcon === fakeChatIconRX
        ? '.chat-header div[title=Minimize]'
        : '.chat-header li[title=Close]'
    );
  var GAEventCategory =
    fakeChatIcon === fakeChatIconRX
      ? 'Website - RXChatBot'
      : 'Website - ChatBot';
  invokeWhenTrue(chatBubble, function() {
    chatBubble().addEventListener('click', function() {
      window.rzpAnalytics({
        eventCategory: GAEventCategory,
        eventAction: 'Click - Open'
      });
    });
  });

  invokeWhenTrue(closeChatButton, function() {
    closeChatButton().addEventListener('click', function() {
      window.rzpAnalytics({
        eventCategory: GAEventCategory,
        eventAction: 'Click - Close'
      });
    });
  });
};

var fakeChatIconPG = $('#fake-chat-icon');
var fakeChatIconRX = $('#fake-chat-icon-rx');
function invokeChatbot(
  fakeChatIcon,
  chatbotSrc,
  productAttribute,
  clientAttribute,
  region
) {
  /* Load analytics only on prod */
  if (beingExecdFrom() === 'website' || beingExecdFrom() === 'beta-website') {
    fakeChatIcon.addClass('loading');
    const initFn = function(d, w, c) {
      if (!d.getElementById('spd-busns-spt')) {
        var n = d.getElementsByTagName('script')[0],
          s = d.createElement('script');
        var loaded = false;
        s.id = 'spd-busns-spt';
        s.defer = 'defer';
        s.src = chatbotSrc;
        s.setAttribute(productAttribute[0], productAttribute[1]);
        s.setAttribute('data-region', region);
        s.setAttribute(clientAttribute[0], clientAttribute[1]);
        if (c) {
          s.onreadystatechange = s.onload = function() {
            if (!loaded) {
              loaded = true;
              c();
            }
          };
        }
        n.parentNode.insertBefore(s, n);
      }
    }.bind(null, document, window, function() {
      var MutationObserver =
        window.MutationObserver || window.WebKitMutationObserver;
      var domChangeCallback = function(mutations) {
        var targetNode = null;

        mutations.forEach(function(mutation) {
          mutation.addedNodes.forEach(node => {
            if (node.getAttribute('id') === 'mobile-chat-container') {
              targetNode = node;
              return true;
            }
            return false;
          });
        });

        if (targetNode) {
          targetNode.click();
          fakeChatIcon.hide();
          observer.disconnect();
          setupChatBotGA(fakeChatIcon);
        }
      };
      var observer = new MutationObserver(domChangeCallback);
      var domElement = document.querySelector('body');

      observer.observe(domElement, {
        childList: true
      });
    });
    if (!window.DISABLE_CHATBOT) {
      setTimeout(initFn, 0);
    }
  }
}

$(document).ready(function() {
  if (fakeChatIconPG) {
    fakeChatIconPG.click(function() {
      invokeChatbot(
        fakeChatIconPG,
        'https://cdn.freshbots.ai/assets/share/js/fbotsChat.min.js',
        ['data-prdct-hash', '72739a6abc259fc06bd3aae6d959178cf1ee37c7'],
        ['data-ext-client-id', '4cd328a801ff11e9b384024204f7674d'],
        'us'
      );
    });
  }
  if (fakeChatIconRX) {
    fakeChatIconRX.click(function() {
      invokeChatbot(
        fakeChatIconRX,
        'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js',
        ['data-bot-hash', 'fde93acc8fd05245a024d744f254726b162d5609'],
        ['data-client', '37829af42dc799bb7d48406f67683a33eed56db5'],
        'in'
      );
    });
  }
});

window.addEventListener('blur', function() {
  const chat = fetchFreddyChatHistory();
  if (chat.length < 10 || !window.localStorage) return;

  const store = {
    history: fetchFreddyChatHistory(),
    consumed: false,
    created_at: Date.now()
  };

  window.localStorage.setItem('rzp-chat-history', JSON.stringify(store));
});

function goog_snippet_vars() {
  var w = window;
  w.google_conversion_id = 928471290;
  w.google_conversion_label = 'Hq25CNOYtXQQ-rHdugM';
  w.google_remarketing_only = false;
}

export function goog_report_conversion(url) {
  document.cookie = 'signup_pixel=1;path=/;domain=.razorpay.com';
  goog_snippet_vars();
  window.google_conversion_format = '3';
  var opt = {};
  opt.onload_callback = function() {
    if (url) {
      window.location = url;
    }
  };
  var conv_handler = window['google_trackConversion'];
  if (typeof conv_handler == 'function') {
    conv_handler(opt);
  } else {
    // adblock
    opt.onload_callback();
  }
}

export function emitFB(event) {
  razorpayAnalytics.track('fb', event);
}

export function emitTwitter(event, data) {
  razorpayAnalytics.track('twitter', event, data);
}

export function emitGA(eventCategory, eventAction, eventLabel, eventValue = 0) {
  window.rzpAnalytics &&
    window.rzpAnalytics({
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    });
}

function newYearCouponCodePricing() {
  let pricingSection;
  if (currentPageID === 'payment-botton')
    pricingSection = $('section#pricing:not(.neo-pricing)');
  else if (currentPageID === 'pricing') pricingSection = $('section.pricing');
  else pricingSection = $('section#pricing-section:not(.neo-pricing)');
  const rates = pricingSection.find('.dynamic-price');
  rates.each(function(index) {
    if (index === 0) {
      const infoColumn =
        currentPageID === 'payment-botton'
          ? null
          : pricingSection.find(
              '.double-heading.new-pricing .column:not(:first)'
            );
      infoColumn && infoColumn.css('flex-grow', 1);
      $(this).text('1.75%');
    } else {
      $(this).text('2.75%');
    }
  });
}
