import { getUrlVars } from './modules/util';
import { qsa, qs, readUTMCookie } from './util';

const MERCHANT_SIGNUP_URL = 'https://dashboard.razorpay.com/signup';
const MERCHANT_SIGNIN_URL = 'https://dashboard.razorpay.com/app/dashboard';
const query = `a[href*="${MERCHANT_SIGNUP_URL}"], a[href*="${MERCHANT_SIGNIN_URL}"]`;

qs(query) &&
  qsa(query).forEach(node => {
    node.addEventListener('click', e => {
      const vars = getUrlVars(node.href);
      const utm = readUTMCookie();
      const utmParams = Object.fromEntries(
        Object.entries(utm.lastUtm).filter(
          ([_, value]) => value != null && value !== ''
        )
      );
      const combinedParams = Object.assign({}, vars, utmParams);
      const queryParams = new URLSearchParams(combinedParams).toString();
      node.href = `${node.href.split('?')[0]}?${queryParams}`;
    });
  });
