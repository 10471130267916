/**
 * This script adds new signup events to all ctas that have the signupSdkCTA class
 * data-section, data-subsection and data-service need to be added accordingly
 */
import { qsa, qs } from './util';
import { getLjEventProps } from './getLjEventProps';
import { trackSegmentAndLJ } from './segment/segment-utils';

(function() {
  const eventName = 'Signup Cta';
  const mobileBreakpoint = 769;

  // Add more pages based on requirement
  const trackingUrls = [
    '/qr-code/',
    '/accept-international-payments/',
    '/optimizer-intelligent-payments-routing/',
    '/capital/instant-settlements/',
    '/invoices/',
    '/smart-collect/',
    '/x/payout-links/',
    '/card-tokenisation/'
  ];

  if (trackingUrls.includes(window.location.pathname)) {
    qs('.signupSdkCTA') &&
      qsa('.signupSdkCTA').forEach(node => {
        node.addEventListener('click', e => {
          const ljEventProps = getLjEventProps();
          const isMobile = window.innerWidth < mobileBreakpoint;

          trackSegmentAndLJ(
            e,
            eventName,
            Object.assign(ljEventProps, {
              pageUrl: window.location.href,
              device_type: isMobile ? 'mweb' : 'dweb',
              target_page: e.currentTarget.href,
              fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
              location: node.getAttribute('data-location') || '',
              source: isMobile ? 'Mobile Website' : 'Website',
              slug: window.location.origin + window.location.pathname,
              mode: 'live',
              version: '1',
              userRole: 'null',
              userId: 'null',
              merchantId: 'null',
              experiment_ID: 'easy_onboarding',
              easyOnboarding: true,
              // sdk explicit events
              label: node.innerText,
              section: node.getAttribute('data-section') || '',
              subSection:
                node.getAttribute('data-subsection') ||
                node.getAttribute('data-section') ||
                '',
              service: node.getAttribute('data-service') || 'PG',
              l1FunnelStage: 'Pre-signup flow',
              l2FunnelStage: 'Signup CTA Click'
            }),
            true
          );
        });
      });
  }
})();
