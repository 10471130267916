// This file has analytics code for dashboard redirection

import { qsa, qs, customeEventsToGTM } from './util';
import { getLjEventProps } from './getLjEventProps';
import { trackSegmentAndLJ } from './segment/segment-utils';
import lj, { ACTIONS } from '../lumberjack';

(function() {
  const SIGNUP_URL = 'https://dashboard.razorpay.com/signup';
  const eventName = 'Signup CTA';
  const mobileBreakpoint = 769;

  const signup_Redirect = [
    '/solutions/e-commerce/',
    '/solutions/education/',
    '/freelancer-individual-business/',
    '/solutions/bfsi/',
    '/solutions/saas/',
  ];

  if (signup_Redirect.includes(window.location.pathname)) {
    qs('.signupCTA') &&
      qsa('.signupCTA').forEach(node => {
        const redirectPathname = !!node.href && node.href.split('?');
        if (
          redirectPathname[0] &&
          redirectPathname[0].includes(SIGNUP_URL)
        ) {
          node.addEventListener('click', (e) => {
            const ljEventProps = getLjEventProps();
            const isMobile = window.innerWidth < mobileBreakpoint;

            trackSegmentAndLJ(
              e,
              eventName,
              Object.assign(ljEventProps, {
                pageUrl: window.location.href,
                device_type: isMobile ? 'mweb' : 'dweb',
                target_page: e.currentTarget.href,
                fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
                location: node.getAttribute('data-location') || '',
                source: isMobile ? 'Mobile Website' : 'Website',
                slug: window.location.origin + window.location.pathname,
                mode: 'live',
                version: '1',
                userRole: 'null',
                userId: 'null',
                merchantId: 'null',
                experiment_ID: 'easy_onboarding',
                easyOnboarding: true
              }),
              true
            );

            lj.track({
              objectName: 'Signup Cta',
              actionName: ACTIONS.CLICKED,
              properties: {
                section: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
                subSection: node.getAttribute('data-location') || ''
              }
            });

            customeEventsToGTM({
              event: `${eventName} Clicked`,
              pageUrl: window.location.href,
              device_type: isMobile ? 'mweb' : 'dweb',
              target_page: e.currentTarget.href,
              fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
              location: node.getAttribute('data-location') || '',
              source: isMobile ? 'Mobile Website' : 'Website',
              slug: window.location.origin + window.location.pathname,
              mode: 'null',
              userRole: 'null',
              userId: 'null',
              merchantId: 'null',
            });
          });
        }
      });
  }
})();
