// This file has analytics code for easy dashboard redirection
// Redirection URL is present in respective pug files. Check for `signup_link`

//  If Redirection url has coupon code, it will not redirect to easy dashboard.
//  Example url - 'https://dashboard.razorpay.com/signup?coupon_code=BOOSTJUNE21' - this url will not redirect to easy dashboard since coupon code is excluded in the dashboard codebase.

import { qsa, qs, customeEventsToGTM } from './util';
import { getLjEventProps } from './getLjEventProps';
import { trackSegmentAndLJ } from './segment/segment-utils';
import lj, { ACTIONS } from '../lumberjack';

(function() {
  const EASY_ONBOARDING_URL = 'https://easy.razorpay.com/onboarding';
  const eventName = 'Sign Up CTA';

  const Easy_Dashboard_Redirect = [
    '/payment-buttons/',
    '/payment-pages/',
    '/payment-links/',
    '/payment-gateway/'
  ];

  if (Easy_Dashboard_Redirect.includes(window.location.pathname)) {
    qs('.signupCTA') &&
      qsa('.signupCTA').forEach(node => {
        const easyDashboardPathname = !!node.href && node.href.split('?');
        if (
          easyDashboardPathname[0] &&
          easyDashboardPathname[0].includes(EASY_ONBOARDING_URL)
        ) {
          node.addEventListener('click', e => {
            const ljEventProps = getLjEventProps();
            const isMobile = window.innerWidth < 769;
            trackSegmentAndLJ(
              e,
              eventName,
              Object.assign(ljEventProps, {
                pageUrl: window.location.href,
                device_type: isMobile ? 'mweb' : 'dweb',
                target_page: e.currentTarget.href,
                fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
                location: node.getAttribute('data-location') || '',
                source: isMobile ? 'Mobile Website' : 'Website',
                slug: window.location.origin + window.location.pathname,
                mode: 'live',
                version: '1',
                userRole: 'null',
                userId: 'null',
                merchantId: 'null',
                experiment_ID: 'easy_onboarding',
                easyOnboarding: true
              }),
              true
            );

            lj.track({
              objectName: 'Signup Cta',
              actionName: ACTIONS.CLICKED,
              properties: {
                section: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
                subSection: node.getAttribute('data-location') || ''
              }
            });

            customeEventsToGTM({
              event: `${eventName} Clicked`,
              pageUrl: window.location.href,
              device_type: isMobile ? 'mweb' : 'dweb',
              target_page: e.currentTarget.href,
              fold: node.getAttribute('data-location') || '', // backward compatibity, hence forth use location instaed of fold.
              location: node.getAttribute('data-location') || '',
              source: isMobile ? 'Mobile Website' : 'Website',
              slug: window.location.origin + window.location.pathname,
              mode: 'null',
              userRole: 'null',
              userId: 'null',
              merchantId: 'null',
              easyOnboarding: true
            });
          });
        }
      });
  }
})();
