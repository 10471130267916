(function() {
  const pathname = window.location.pathname;

  const isSupportPage = pathname.includes('/support/');
  const isPaymentsSupportPage = pathname.includes('/support/payments/');
  const isBankingPlusSupportPage = pathname.includes('/support/banking-plus/');
  const isPayrollSupportPage = pathname.includes('/support/payroll/');
  const isPOSSupportPage = pathname.includes('/support/pos/');
  const isDigitalLendingSupportPage = pathname.includes(
    '/support/digital-lending/'
  );
  const isXPage = pathname.includes('/x/');
  const isPayrollPage = pathname.includes('/payroll/');
  const isRTSPLPage =
    pathname === '/capital/' ||
    pathname === '/x/corporate-cards/' ||
    pathname === '/x/digital-lending/';
  const footerComplianceElement = document.querySelectorAll(
    '#footer-compliance-text'
  );
  const footerAddressElement = document.querySelectorAll('#footer-address');

  for (let i = 0; i < footerComplianceElement.length; i++) {
    if (isSupportPage) {
      if (isPaymentsSupportPage) {
        footerComplianceElement[i].textContent =
          'Razorpay Software Private Limited (RSPL) is an RBI Autorised Online Payment Aggregator';
      } else if (isBankingPlusSupportPage) {
        footerComplianceElement[i].textContent =
          'Banking+ & Payroll are operated under RZPX Private Limited';
      } else if (isPayrollSupportPage) {
        footerComplianceElement[i].textContent =
          'Banking+ & Payroll are operated under RZPX Private Limited';
      } else if (isPOSSupportPage) {
        footerComplianceElement[i].textContent = '';
      } else if (isDigitalLendingSupportPage) {
        footerComplianceElement[i].textContent =
          'Digital Lending is operated under Razorpay Tech Solutions Private Limited, a Lending Service Provider to Regulated Entities authorised by RBI.';
      } else {
        footerComplianceElement[i].textContent =
          'Razorpay Software Private Limited (RSPL) is an RBI Autorised Online Payment Aggregator';
      }
    } else if (isRTSPLPage) {
      footerComplianceElement[i].textContent =
        'Digital Lending is operated under Razorpay Tech Solutions Private Limited, a Lending Service Provider to Regulated Entities authorised by RBI.';
    } else if (isXPage || isPayrollPage) {
      footerComplianceElement[i].textContent =
        'Banking+ & Payroll are operated under RZPX Private Limited';
    } else {
      footerComplianceElement[i].textContent =
        'Razorpay Software Private Limited (RSPL) is an RBI Autorised Online Payment Aggregator';
    }
  }

  for (let i = 0; i < footerAddressElement.length; i++) {
    if (isSupportPage) {
      if (isPaymentsSupportPage) {
        footerAddressElement[i].textContent = `
          Razorpay Software Private Limited,
          1st Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi,
          Bengaluru, 560030,
          Karnataka, India  
          CIN:U72200KA2013PTC097389 
          © Razorpay 2024
          All Rights Reserved
        `;
      } else if (isBankingPlusSupportPage) {
        footerAddressElement[i].textContent = `
          RZPX Private Limited,
          Ground Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi
          Bengaluru, 560030,
          Karnataka, India
          CIN:U72900KA2020PTC139072
        `;
      } else if (isPayrollSupportPage) {
        footerAddressElement[i].textContent = `
          RZPX Private Limited,
          Ground Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi
          Bengaluru, 560030,
          Karnataka, India
          CIN:U72900KA2020PTC139072
        `;
      } else if (isPOSSupportPage) {
        footerAddressElement[i].textContent = `
          Ezetap Mobile Solutions Private Limited
          1st Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi,
          Bengaluru, 560030,
          Karnataka, India
          CIN:U64203KA2011PTC059891
        `;
      } else if (isDigitalLendingSupportPage) {
        footerAddressElement[i].textContent = `
          Razorpay Tech Solutions Private Limited,
          First Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi,
          Bengaluru, Karnataka,
          India, 560030
          CIN:U82990KA2023PTC172200
        `;
      } else {
        footerAddressElement[i].textContent = `
          Razorpay Software Private Limited,
          1st Floor, SJR Cyber,
          22 Laskar Hosur Road, Adugodi,
          Bengaluru, 560030,
          Karnataka, India  
          CIN:U72200KA2013PTC097389 
          © Razorpay 2024
          All Rights Reserved
        `;
      }
    } else if (isRTSPLPage) {
      footerAddressElement[i].textContent = `
        Razorpay Tech Solutions Private Limited,
        First Floor, SJR Cyber,
        22 Laskar Hosur Road, Adugodi,
        Bengaluru, Karnataka,
        India, 560030
        CIN:U82990KA2023PTC172200
      `;
    } else if (isXPage) {
      footerAddressElement[i].textContent = `
        RZPX PRIVATE LIMITED,
        Ground Floor, SJR Cyber,
        22 Laskar Hosur Road, Adugodi,
        Bengaluru, 560030,
        Karnataka, India
        CIN:U72900KA2020PTC139072
      `;
    } else {
      footerAddressElement[i].textContent = `
        Razorpay Software Private Limited,
        1st Floor, SJR Cyber,
        22 Laskar Hosur Road, Adugodi,
        Bengaluru, 560030,
        Karnataka, India  
        CIN:U72200KA2013PTC097389 
        © Razorpay 2024
        All Rights Reserved
      `;
    }
  }
})();
