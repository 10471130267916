import './pollyfills';
import './GA';
import './nav';
import './footer';
import { onResize as onResizeSecNav } from 'tabs';
import 'carousal';
import 'carousel-new';
import 'video';
import { qsa } from './modules/util';
import { onResize as onResizeNav, onScroll as onScrollNav } from 'secnav';
import { open as openModal, hide as hideModal } from 'modal';
import 'marketing';
import 'section-view-trigger';
import 'smooth-scroll';
import './grievances';
import './bouncy-currency';
import './gaBounceRate';
import { executeWhenIdle } from './delay';
import lj from './lumberjack';
import './segment/segment-events';
import './utm-on-redirect';
import './easy-onboarding-analytics';
import './signup-dashboard-analytics';
import './signup-events-sdk';
import { isMobile, trackSegment } from './segment/segment-utils';

import {
  debounce,
  isInViewport,
  throttle,
  getUrlVars,
  qs,
  setCookie
} from './modules/util';
import './timer';
import { getLjEventProps } from './getLjEventProps';
import { triggerWebVitalLogs } from './web-vitals';
import fireSectionViewed from './modules/trigger-section-impression';
import homePageEvents from './modules/homepage-events';

if (window.location.hostname === 'razorpay.com' && window.lazyLoadInstance) {
  lazyLoadInstance.update();
}

const invoke = func => func();
const isFunction = func => typeof func === 'function';

var enableTimer;
var $rootElement = $('html');
const disableHover = _ => $rootElement.addClass('no-hover');
const enableHover = _ => $rootElement.removeClass('no-hover');

const scrollListeners = [
  () => {
    disableHover();
    clearTimeout(enableTimer);
    enableTimer = setTimeout(enableHover, 500);
  },
  onScrollNav
].filter(isFunction);
const resizeListeners = [onResizeNav, onResizeSecNav].filter(isFunction);

const addListeners = (event, listeners) =>
  listeners.length && addEventListener(event, e => listeners.forEach(invoke));

addListeners('scroll', scrollListeners);
addListeners('resize', resizeListeners);

// triggers view section events
let allSections = document.querySelectorAll('.view-trigger');
let sectionViewScrollListener = fireSectionViewed(allSections, lj);

//include a bunch of homepage events
if (location.pathname === '/') {
  homePageEvents(lj);
}

window.addEventListener('scroll', debounce(fireLJOnScroll, 1000), true);
window.addEventListener(
  'scroll',
  throttle(() => {
    fireLJOnFooter();
    sectionViewScrollListener();
  }, 1000),
  true
);

resizeListeners.forEach(invoke);

var listHeight = 40;

function closeDropdown() {
  $(window).off('click', closeDropdown);
  let $btn = $('.dropdown.open')
    .removeClass('open')
    .children('.btn');
  setTimeout(function() {
    $btn.height(listHeight);
  }, 70);
}

$('.dropdown').click(function(e) {
  e.stopPropagation();
  var $this = $(this);
  var isOpen = $this.hasClass('open');
  if (isOpen) {
    if (e.target.nodeName === 'LI') {
      var $target = $(e.target);
      var span = $this.find('span');
      var orig = span.html();
      setTimeout(() => {
        span.html($target.html());
        $target.remove();
        $(this)
          .children('ul')
          .prepend(`<li>${orig}</li>`);
      }, 300);
    }
    closeDropdown();
  } else {
    $(window).one('click', closeDropdown);
    var numLi = $this.find('li').length;
    $this
      .addClass('open')
      .children('.btn')
      .height((numLi + 1) * listHeight + 4);
  }
});

window.openModal = openModal;
window.hideModal = hideModal;

// $('#banner input').focus();

function gaEventForLink(action) {
  return function(e) {
    e.preventDefault();
    // Checking for length because GA might be noop. (Not the perfect check, but this is all I can think of.)
    if (ga && ga.length) {
      ga('old.send', 'event', action, 'click');
      ga('send', 'event', action, 'click', {
        hitCallback: function() {
          window.location.href = e.target.href;
        }
      });
    } else {
      window.location.href = e.target.href;
    }
  };
}

// GA for Hiring Banner.
$('.hiring-btn').click(gaEventForLink('Click - Join the team now'));

// GA for Tech page footer CTA
$('.footer_hiring_cta').click(
  gaEventForLink('Click - Apply Now on Tech Footer')
);

qsa('.signupCTA').forEach(cta => {
  cta.addEventListener('click', function() {
    window.gtag('event', 'Website Signup Cta Click Initiated', {
      event_category: 'Unknown',
      event_label: cta.innerText,
      send_to: 'G-F42RZ69DW8'
    });
  });
});

$(
  '.enterprise-form, .contact-form, .earlyaccess-form,.download-ebook-form, .whatwentwrong-form, .payment-pages-form, .razorpay-x-form, .capital-form, .moo-form, .capital-loan-form, .razorpay-x-form, .flash-credit-modal-form, .optimizer-form'
).each(function(index, form) {
  form.onsubmit = function(e) {
    e.preventDefault();
    var $form = $(this);
    var $button = $form.find('button');
    var origText = $button.html();
    $button.prop('disabled', 'true').html('Please Wait...');
    $(this)
      .find('input[name="ref_url"]')
      .remove();
    $(this).append(
      '<input type="hidden" name="ref_url" value="' +
        window.location.href.split('?')[0] +
        '" /> '
    );

    $.ajax({
      method: 'get',
      url: this.action,
      data: $(this).serialize(),
      complete: function(r) {
        $button.prop('disabled', false).html(origText);
        if (!r.responseJSON) {
          // submissionInfo(parent, 'There was an error, please check your network connection.');
        } else if (r.responseJSON.status === 'success') {
          $form[0].reset();
          $form.parents('.modal:eq(0)').addClass('success');
          //if its a download ebook btn and white-papers page then
          var dowloadEbook = $('#download-ebook');
          var whitePapers = $('#white-papers');
          if (dowloadEbook.length && whitePapers.length) {
            $form
              .parents('.modal:eq(0)')
              .find('.hidden-download')
              .submit();
            $form
              .parents('.modal:eq(0)')
              .find('.close')
              .click();
          }
        } else {
          // submissionInfo(parent, 'There was an error in submitting your form');
        }
      }
    });
  };
});

$('#pricing-plan-btn').click(function() {
  openModal('#union-budget-modal');

  /* if (!document.getElementById('screen_7')) {
    window.location.href =
      'https://razorpay.com/x/?scrollTo=pricing&utm_source=xwebsite&utm_medium=banner&utm_campaign=new-pricing-announcement';
  } else {
    $('html, body').animate({
      scrollTop: $('#screen_7').position().top + 100
    });
  } */
});

$('#pricing-plan-mobile-btn').click(function() {
  openModal('#union-budget-modal');
  /* if (!document.getElementById('screen_7_m')) {
    window.location.href =
      'https://razorpay.com/x/?scrollTo=pricing&utm_source=xwebsite&utm_medium=banner&utm_campaign=new-pricing-announcement';
  } else {
    $('html, body').animate({
      scrollTop: $('#screen_7_m').position().top + 100
    });
  } */
});

$('.vendor-payments-btn').click(function() {
  window.location.href =
    'https://razorpay.com/x/vendor-payments/?utm_source=xwebsite&utm_medium=banner&utm_campaign=vendorpregtm';
});

// update copyright automatically using client date
let copyright = document.querySelector('#cp-year');
let currentYear = new Date().getFullYear();
if (copyright && copyright.innerText !== currentYear) {
  copyright.innerText = currentYear;
}

let midExists =
  (window.razorpayAnalytics &&
    razorpayAnalytics.utils.getCookie('midExists')) ||
  false;

let ljEventProps = getLjEventProps();
function fireLJOnScroll(event) {
  if (userStayedOnPage) {
    event.stopPropagation();
    lj.pushEvents({
      event_name: 'website.page_read',
      event_type: 'success',
      properties: {
        title: document.getElementsByTagName('title')[0].innerText,
        midExists,
        first_page_session: ljEventProps.isFirstPageSession,
        first_page_user: ljEventProps.isFirstPageUser,
        version: 1,
        device_type: isMobile() ? 'mweb' : 'dweb'
      }
    });
  }
}

let isFooterEventFired = false;
let footer = $('footer')[0];
function fireLJOnFooter() {
  if (footer && isInViewport(footer) && !isFooterEventFired) {
    lj.pushEvents({
      event_name: 'website.bottom_nav_menu',
      event_type: 'viewed',
      properties: {
        title: document.getElementsByTagName('title')[0].innerText,
        midExists,
        version: 1,
        device_type: isMobile() ? 'mweb' : 'dweb'
      }
    });
    isFooterEventFired = true;
  }
}
footer &&
  footer.addEventListener('click', function(event) {
    lj.pushEvents({
      event_name: 'website.bottom_nav_link',
      event_type: 'clicked',
      properties: {
        title: document.getElementsByTagName('title')[0].innerText,
        midExists,
        version: 1,
        label: event.target.innerText,
        destinationURL: event.target.href,
        device_type: isMobile() ? 'mweb' : 'dweb'
      }
    });
  });

let userStayedOnPage = false;
setTimeout(() => {
  userStayedOnPage = true;
}, 15000);

/*
  Offer Ribbon tracking
*/
let trackEventFired = false,
  offerRibbon;

const handleDataLakeEvents = () => {
  if (isInViewport(offerRibbon) && !trackEventFired) {
    lj.pushEvents({
      event_name: 'website.display_newpricingoffer',
      event_type: 'success',
      properties: {
        title: document.getElementsByTagName('title')[0].innerText,
        midExists,
        first_page_session: ljEventProps.isFirstPageSession,
        first_page_user: ljEventProps.isFirstPageUser,
        version: 1,
        offerText: offerRibbon.innerText,
        bannerId: offerRibbon.id,
        device_type: isMobile() ? 'mweb' : 'dweb'
      }
    });
    trackEventFired = true;
  }
};

if (window.location.href.includes('debugWebVitals')) {
  triggerWebVitalLogs();
}

window.onload = e => {
  trackEventFired = false;
  offerRibbon = document.querySelector('.offer-ribbon');
  var x = document.getElementsByTagName('title')[0];
  trackSegment(e, 'Page Viewed', {
    slug: window.location.pathname,
    pageUrl: window.location.href,
    PageTitle: x.textContent
  });
  if (offerRibbon)
    window.addEventListener('scroll', debounce(handleDataLakeEvents, 100));
};
